(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/set-global/set-global.js') >= 0) return;  svs.modules.push('/components/utils/set-global/set-global.js');
"use strict";


(() => {
  const isObject = val => "".concat(val) === '[object Object]';
  function setGlobal(path, value) {
    const parts = path.split('.');
    const pathSegments = [];
    let currentPath = window;
    while (parts.length > 1) {
      const step = parts.shift();
      pathSegments.push(step);
      if (currentPath[step] !== undefined && !isObject(currentPath[step])) {
        throw new Error("Failed trying to set property `".concat(step, "` to non-object value in path`").concat(pathSegments.join('.'), "`"));
      }
      currentPath[step] = currentPath[step] || {};
      currentPath = currentPath[step];
    }
    const step = parts[0];
    pathSegments.push(step);
    if (currentPath[step] === undefined) {
      currentPath[step] = value;
    } else if (!isObject(currentPath[step])) {
      throw new Error("Failed trying to set value on non-object in `".concat(pathSegments.join('.'), "`"));
    } else if (isObject(value)) {
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          if (currentPath[step][key] !== undefined) {
            throw new Error("Failed trying to set value on existing property `".concat(key, "` in `").concat(pathSegments.join('.'), "`"));
          }
          currentPath[step][key] = value[key];
        }
      }
    } else {
      throw new Error("Failed trying to set non-object value on object in path `".concat(pathSegments.join('.'), "`"));
    }
    return currentPath[step];
  }
  if (!window.setGlobal) {
    Object.defineProperty(window, 'setGlobal', {
      value: setGlobal,
      writable: false,
      configurable: false,
      enumerable: true
    });
  }
})();

 })(window);